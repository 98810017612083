import React from 'react';
import logo from './anusha.jpg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Anusha Nakshathra
        </p>
        <p className="links">
          <a href="https://instagram.com/anusha_viaggiatore" target="_blank" rel="noopener noreferrer">Instagram</a>
          &nbsp;-&nbsp;
          <a href="https://www.facebook.com/anusha.rao.90" target="_blank" rel="noopener noreferrer">Facebook</a>
          &nbsp;-&nbsp;
          <a href="https://twitter.com/anu_viaggiatore" target="_blank" rel="noopener noreferrer">Twitter</a>
          &nbsp;-&nbsp;
          <a href="https://www.tripoto.com/profile/anushanakshathra" target="_blank" rel="noopener noreferrer">Tripoto</a>
          &nbsp;-&nbsp;
          <a href="https://www.youtube.com/channel/UC8wDj-7IOnoMIb0HnznQzzA" target="_blank" rel="noopener noreferrer">YouTube</a>
        </p>
      </header>
    </div>
  );
}

export default App;
